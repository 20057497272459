/* @import "style.css"; */
@font-face {
  font-family: "Noto";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/Noto/NotoSansEthiopic-Regular.ttf) format("truetype"),
    url(fonts/Noto/NotoSansEthiopic-Bold.ttf) format("truetype");
  unicode-range: 1200–137f;
}

a h1,
h2,
h3,
h4,
h5,
h6,
p,
.a .h1,
.h2,
.h3,
.h4,
.h5,
.h6,
body {
  font-family: Heebo !important;
}

.nm,
.nm a,
.nm h1,
.nm h2,
.nm h3,
.nm h4,
.nm h5,
.nm h6,
.nm p,
.nm .a,
.nm .h1,
.nm .h2,
.nm .h3,
.nm .h4,
.nm .h5,
.nm .h6,
.nm body {
  font-family: Heebo !important;
}

.am,
.am a,
.am h1,
.am h2,
.am h3,
.am h4,
.am h5,
.am h6,
.am p,
.am .a,
.am .h1,
.am .h2,
.am .h3,
.am .h4,
.am .h5,
.am .h6,
.am body {
  font-family: Noto !important;
}

.en,
.en a,
.en h1,
.en h2,
.en h3,
.en h4,
.en h5,
.en h6,
.en p,
.en .a,
.en .h1,
.en .h2,
.en .h3,
.en .h4,
.en .h5,
.en .h6,
.en body {
  font-family: Heebo !important;
}

.old-price {
  position: relative;
  color: #909cb5;
  font-size: smaller;
}
.old-price::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #909cb5;
}

.has-tooltip2 {
  position: relative;
}

.tooltip2 {
  display: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
  background-color: #00c0a1;
  border-radius: 2px;
  position: absolute;
  bottom: calc(100% + 14px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  min-width: 134px;
  padding: 1px 8px;
  text-align: center;
}

.has-tooltip {
  position: relative;
}

.tooltip {
  display: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
  background-color: #00c0a1;
  border-radius: 2px;
  position: absolute;
  bottom: calc(250% + 14px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  min-width: 134px;
  padding: 1px 8px;
  text-align: center;
}

@media (min-width: 741px) {
  .tooltip {
    display: block;
  }
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-top: 5px solid #00c0a1;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.site-footer {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  background: #fff;
  color: #6b7a90;
}

.image-button-container {
  margin-left: 1em;
  display: inline-flex;
  cursor: pointer;
  align-items: inherit;
}

.image-button-container-center {
  /* margin-left: 1em; */
  display: inline-flex;
  cursor: pointer;
  align-items: inherit;

  margin-top: 4em;
  margin-bottom: 2em;
}

.link-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
}

.site-footer-inner {
  justify-content: space-between;
  /* padding-top: 72px; */
  padding-bottom: 22px;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
}

/* language option */
.radiowrapper {
  /* position: absolute; */
  padding-right: 8px;
}

.radiowrapper input {
  visibility: hidden;
  width: 0;
}

.radiowrapper label:hover {
  color: #f9a825;
}

.radiowrapper input:checked + label {
  color: #f9a825;
}

.language-label {
  font-size: 14px;
  color: #d1d1d1;
}

.login-button:hover {
  text-decoration: underline !important;
}

.login-button {
  color: #81673d !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

.sr-only {
  display: none;
}

.dashboard-card-container {
  margin-top: 44px;
}

.summary-card-container {
  min-height: 280px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.summary-container {
  min-height: 100px;
}

.summary-top {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #000000;
}

.summary-middle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 54px;
  line-height: 84px;
  text-align: center;

  color: #000000;
}

.summary-bottom {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #000000;
}

.stat-holder {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  justify-content: center;
  align-items: center;
}

.stat-card-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 8px;

  color: #00000051;
}

.stat-container {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 12px;
  margin-left: 12px;
  min-width: 80px;
}

.RaLink-link-64:hover,
.RaLink-link-64:focus,
.RaLink-link-64:active {
  text-decoration: none !important;
}

.stat-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  color: #000000;
}

.stat-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 54px;
  text-align: center;

  color: #000000;
  margin-top: 4px;
}

.stat-value-small {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 54px;
  text-align: center;

  color: #000000;
  margin-top: 4px;
}

.card-title {
  margin: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #000000;
}

.dashboard-picker {
  display: flex;
  justify-content: flex-end;
}

/* #wrap1 {
  left: 100px;
  top: 50px;
}

#wrap2 {
  left: 80px;
  top: 100px;
}

#wrap3 {
  left: 0px;
  top: 150px;
} */

.callwrapper {
  /* position: absolute; */
  padding-right: 8px;
  outline: "none";
  cursor: pointer;
  /* padding-top: 0.25rem; */
  margin-right: 1.5rem;
}

.call-icon {
  vertical-align: middle;
  display: inline-block;
  font-size: 14px;
  margin-right: 0.5rem;
}

.call-text {
  /* vertical-align: middle; */
  /* display: inline-block; */
  font-size: 14px;
  color: #d1d1d1;
  /* padding: 0px 8px; */
  color: #01100a;
  font-weight: 600;
  cursor: pointer;
}

.pricing-block {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-direction: column;

  max-width: 300px;
  align-self: center;
  text-align: center;
  /* width: 100%; */

  /* margin: 12px; */
  /* border-radius: 4px; */
}

.pricing-title-ras {
  font-weight: 500 !important;
  font-size: 18px !important;

  color: #f99420;
}

.pricing-title-qetari {
  font-weight: 500 !important;
  font-size: 18px !important;

  color: #f74d4e;
}

.pricing-title {
  font-weight: 600 !important;
  font-size: 24px !important;

  color: #01100a4e !important;
}

.pricing-title-nigd {
  font-weight: 500 !important;
  font-size: 18px !important;

  color: #31ddb7;
}

.pricing-monthly {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;

  margin: 24px;
}

.subscription-price {
  font-weight: 800 !important;
  font-size: 36px !important;
  color: #000000;
  margin: 12px;
}

.pricing-details {
  width: 100%;

  text-align: center;
  align-self: center;
  align-content: center;
  background: #ffffff;
  padding: 24px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  /* border-style: solid;
  border-color: rgba(255, 255, 255, 0.25);
  border-radius: 8px; */
  /* margin: 12px; */
}

.pricing-details-ras {
  width: 100%;

  text-align: center;
  background: #f99420;
  padding: 24px;
}

.pricing-details-qetari {
  width: 100%;

  text-align: center;
  background: #f74d4e;
  padding: 24px;
}

.pricing-details-nigd {
  width: 100%;

  text-align: center;
  background: #31ddb7;
  padding: 24px;
}

.pricing-feature {
  padding: 8px;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #01100a;
}

.centered-text {
  width: 100% !important;
  text-align: center !important;
  align-items: center !important;
  align-self: center !important;
  align-content: center !important;
  /* display: flex; */
  /* flex-direction: row; */
}

@media (min-width: 641px) {
  .hero-small {
    text-align: left;
    padding-top: 48px;
    padding-bottom: 32px;
  }
}

.hero-small {
  text-align: left;
  padding-top: 48px;
  padding-bottom: 32px;
}

.subscription-block {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-direction: column;

  max-width: 300px;
  /* align-self: center; */
  text-align: center;
  vertical-align: bottom;
  /* width: 100%; */

  position: relative;
  padding: 16px;
  margin-top: 3rem;

  /* position: relative;
  padding: 16px;
  width: 388px;
  max-width: 388px;
  flex-grow: 1; */
}

.subscription-box {
  width: 100%;

  position: relative;
  text-align: center;
  align-self: center;
  align-content: center;

  padding: 16px;

  width: 100%;
  padding: 48px 24px 72px;
  /* background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px; */
  /* 
    position: relative;
  height: 100%;
  background: #fff;
  padding: 48px 24px 72px; */
}
.subscription-box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 24px 48px rgb(1 16 10 / 12%);
  mix-blend-mode: multiply;
  pointer-events: none;
}
/* .feature {
  position: relative;
  padding: 16px;
  width: 388px;
  max-width: 388px;
  flex-grow: 1;
} */
/* .feature-inner {
  position: relative;
  height: 100%;
  background: #fff;
  padding: 48px 24px 72px;
} */
@media (min-width: 641px) {
  .subscription-box {
    padding: 24px;
  }
}

.subscription-title {
  width: 100%;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  margin: 12px;

  align-items: center;
  text-align: center;

  color: #000000;
}

.subscription-description {
  width: 100%;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  margin-bottom: 24px;

  align-items: center;
  text-align: center;

  color: #878c93;
}

.subscription-container {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;

  margin-left: 24px;
  margin-right: 24px;
}

.subscription-currency {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 6px;
  /* identical to box height */

  align-items: center;
  text-align: center;
  align-self: center;
  vertical-align: top;

  color: #002200;
}

.subscription-value {
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 48px;
  /* identical to box height */

  align-items: center;
  text-align: center;

  color: #002200;
}

.subscription-duration {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  align-items: center;
  text-align: center;

  color: #002200;
}

.subscription-payment {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 24px;

  align-items: center;
  text-align: center;

  color: #767984;
}
.has-tooltip3 {
  position: relative;
}

.tooltip3 {
  display: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
  background-color: #00c0a1;
  border-radius: 2px;
  position: absolute;
  bottom: calc(100% + 14px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  min-width: 134px;
  padding: 1px 8px;
  text-align: center;
}

/* new */
button,
input,
optgroup,
select,
textarea {
  /* font-family: sans-serif; */
  /* font-size: 100%; */
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  display: inline-block;
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details,
menu {
  display: block;
}
summary {
  /* display: list-item; */
}
canvas {
  display: inline-block;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  background: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
hr {
  border: 0;
  display: block;
  height: 1px;
  background: #d2daf0;
  background: linear-gradient(
    to right,
    rgba(210, 218, 240, 0.1) 0,
    rgba(210, 218, 240, 0.6) 50%,
    rgba(210, 218, 240, 0.1) 100%
  );
  margin-top: 24px;
  margin-bottom: 24px;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 24px;
  padding-left: 24px;
}
ul {
  list-style: disc;
}
ol {
  list-style: decimal;
}
li > ul,
li > ol {
  margin-bottom: 0;
}
dl {
  margin-top: 0;
  margin-bottom: 24px;
}
dt {
  font-weight: 700;
}
dd {
  margin-left: 24px;
  margin-bottom: 24px;
}
/* img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
} */
figure {
  margin: 24px 0;
}
figcaption {
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
}
img,
svg {
  display: block;
}
table {
  border-collapse: collapse;
  margin-bottom: 24px;
  width: 100%;
}
tr {
  border-bottom: 1px solid #d2daf0;
}
th {
  text-align: left;
}
th,
td {
  padding: 10px 16px;
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}
html {
  font-size: 18px;
  line-height: 27px;
}
@media (min-width: 641px) {
  html {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.1px;
  }
}
body {
  /* color: #567868; */
  font-size: 1rem;
}
body,
button,
input,
select,
textarea {
  /* font-family: "Heebo", sans-serif; */
}
a {
  color: #00897b;
  text-decoration: none;
}
a:hover,
a:active {
  outline: 0;
  text-decoration: underline;
}
/* h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  clear: both;
  color: #01100a;
  font-family: Fira Sans, serif;
  font-weight: 600;
} */
h1,
.h1 {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0px;
}
@media (min-width: 641px) {
  h1,
  .h1 {
    font-size: 46px;
    line-height: 56px;
    letter-spacing: 0px;
  }
}
h2,
.h2 {
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0px;
}
@media (min-width: 641px) {
  h2,
  .h2 {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0px;
  }
}
h3,
.h3,
blockquote {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.1px;
}
@media (min-width: 641px) {
  h3,
  .h3,
  blockquote {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0px;
  }
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.1px;
}
@media (min-width: 641px) {
  h4,
  h5,
  h6,
  .h4,
  .h5,
  .h6 {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.1px;
  }
}
@media (max-width: 640px) {
  .h1-mobile {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0px;
  }
  .h2-mobile {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0px;
  }
  .h3-mobile {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.1px;
  }
  .h4-mobile,
  .h5-mobile,
  .h6-mobile {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.1px;
  }
}
.text-light {
  color: rgba(255, 255, 255, 0.4);
}
.text-light a {
  color: rgba(255, 255, 255, 0.4);
}
.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light .h1,
.text-light .h2,
.text-light .h3,
.text-light .h4,
.text-light .h5,
.text-light .h6 {
  color: #fff !important;
}
.text-sm {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.1px;
}
.text-xs {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
}
h1,
h2,
.h1,
.h2 {
  margin-top: 48px;
  margin-bottom: 16px;
}
h3,
.h3 {
  margin-top: 36px;
  margin-bottom: 12px;
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  margin-top: 24px;
  margin-bottom: 4px;
}
p {
  margin-top: 0;
  margin-bottom: 24px;
}
dfn,
cite,
em,
i {
  font-style: italic;
}
blockquote {
  color: #ababc9;
  font-style: italic;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
}
blockquote::before {
  content: "\201C";
}
blockquote::after {
  content: "\201D";
}
blockquote p {
  display: inline;
}
address {
  color: #567868;
  border-width: 1px 0;
  border-style: solid;
  border-color: #d2daf0;
  padding: 24px 0;
  margin: 0 0 24px;
}
/* pre,
pre h1,
pre h2,
pre h3,
pre h4,
pre h5,
pre h6,
pre .h1,
pre .h2,
pre .h3,
pre .h4,
pre .h5,
pre .h6 {
  font-family: "Courier 10 Pitch", Courier, monospace;
} */
/* pre,
code,
kbd,
tt,
var {
  background: #eef1fa;
}
pre {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}
code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 16px;
  padding: 2px 4px;
} */
abbr,
acronym {
  cursor: help;
}
mark,
ins {
  text-decoration: none;
}
small {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.1px;
}
b,
strong {
  font-weight: 700;
}
button,
input,
select,
textarea,
label {
  font-size: 18px;
  line-height: 27px;
}
.container,
.container-sm {
  width: 100%;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 481px) {
  .container,
  .container-sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.container {
  max-width: 1128px;
}
.container-sm {
  max-width: 848px;
}
.container .container-sm {
  max-width: 800px;
  padding-left: 0;
  padding-right: 0;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
}
.screen-reader-text:focus {
  border-radius: 2px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  display: block;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
  text-decoration: none;
  background-color: #fff;
  color: #00897b !important;
  border: none;
  height: auto;
  left: 8px;
  padding: 16px 32px;
  top: 8px;
  width: auto;
  z-index: 100000;
}
.list-reset {
  list-style: none;
  padding: 0;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-primary {
  color: #00897b;
}
.text-secondary {
  color: #ff6381;
}
.has-top-divider {
  position: relative;
}
.has-top-divider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #d2daf0;
  background: linear-gradient(
    to right,
    rgba(210, 218, 240, 0.1) 0,
    rgba(210, 218, 240, 0.6) 50%,
    rgba(210, 218, 240, 0.1) 100%
  );
}
.has-bottom-divider {
  position: relative;
}
.has-bottom-divider::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: #d2daf0;
  background: linear-gradient(
    to right,
    rgba(210, 218, 240, 0.1) 0,
    rgba(210, 218, 240, 0.6) 50%,
    rgba(210, 218, 240, 0.1) 100%
  );
}
.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.m-56 {
  margin: 56px;
}
.mt-56 {
  margin-top: 56px;
}
.mr-56 {
  margin-right: 56px;
}
.mb-56 {
  margin-bottom: 56px;
}
.ml-56 {
  margin-left: 56px;
}
.m-64 {
  margin: 64px;
}
.mt-64 {
  margin-top: 64px;
}
.mr-64 {
  margin-right: 64px;
}
.mb-64 {
  margin-bottom: 64px;
}
.ml-64 {
  margin-left: 64px;
}
.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.p-56 {
  padding: 56px;
}
.pt-56 {
  padding-top: 56px;
}
.pr-56 {
  padding-right: 56px;
}
.pb-56 {
  padding-bottom: 56px;
}
.pl-56 {
  padding-left: 56px;
}
.p-64 {
  padding: 64px;
}
.pt-64 {
  padding-top: 64px;
}
.pr-64 {
  padding-right: 64px;
}
.pb-64 {
  padding-bottom: 64px;
}
.pl-64 {
  padding-left: 64px;
}
.sr .has-animations .is-revealing {
  visibility: hidden;
}
.has-animations .anime-element {
  visibility: hidden;
}
.anime-ready .has-animations .anime-element {
  visibility: visible;
}
.input,
.textarea {
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #d2daf0;
  border-radius: 2px;
  color: #567868;
  max-width: 100%;
  width: 100%;
}
.input::placeholder,
.textarea::placeholder {
  color: #ababc9;
}
.input::-ms-input-placeholder,
.textarea::-ms-input-placeholder {
  color: #ababc9;
}
.input:-ms-input-placeholder,
.textarea:-ms-input-placeholder {
  color: #ababc9;
}
.input:hover,
.textarea:hover {
  border-color: #bfcaea;
}
.input:active,
.input:focus,
.textarea:active,
.textarea:focus {
  outline: none;
  border-color: #00897b;
}
.input[disabled],
.textarea[disabled] {
  cursor: not-allowed;
  background-color: #eef1fa;
  border-color: #eef1fa;
}
.input {
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 16px;
  letter-spacing: -0.1px;
  line-height: 20px;
  padding: 13px 16px;
  height: 48px;
  box-shadow: none;
}
.input .inline-input {
  display: inline;
  width: auto;
}
.textarea {
  display: block;
  min-width: 100%;
  resize: vertical;
}
.textarea .inline-textarea {
  display: inline;
  width: auto;
}
.field-grouped > .control:not(:last-child) {
  margin-bottom: 8px;
}
@media (min-width: 641px) {
  .field-grouped {
    display: flex;
  }
  .field-grouped > .control {
    flex-shrink: 0;
  }
  .field-grouped > .control.control-expanded {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .field-grouped > .control:not(:last-child) {
    margin-bottom: 0;
    margin-right: 8px;
  }
}
.button {
  display: inline-flex;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 16px;
  text-decoration: none !important;
  background-color: #fff;
  color: #00897b !important;
  /* border: none;  */
  border: 1px solid #00897b;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  padding: 16px 32px;
  height: 48px;
  text-align: center;
  white-space: nowrap;
}
.button:active {
  outline: 0;
}
.button::before {
  border-radius: 2px;
}
.button-sm {
  padding: 8px 24px;
  height: 32px;
}
.button-primary {
  color: #fff !important;
  transition: background 0.15s ease;
}
.button-primary {
  background: #00897b;
  color: #ffffff !important;
}
.button-primary:hover {
  background: #007f72;
}
.button-block {
  display: flex;
}
.site-header {
  position: relative;
  padding: 24px 0;
}
.header-shape {
  position: absolute;
}
.header-shape-1 {
  display: none;
  top: 0;
}
.header-shape-2 {
  top: -40px;
  right: -53px;
}
.site-header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-links {
  display: inline-flex;
}
.header-links li {
  display: inline-flex;
}
.header-links a:not(.button) {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  text-decoration: none;
  line-height: 16px;
  padding: 8px 24px;
}
.header-links a:not(.button):hover,
.header-links a:not(.button):active {
  color: #fff;
}
@media (min-width: 641px) {
  .header-shape-1 {
    display: block;
    left: 687px;
  }
  .header-shape-2 {
    top: -92px;
    left: 440px;
    right: auto;
  }
}

.control {
  padding-top: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.features {
  position: relative;
}
.features::before {
  content: "";
  width: 100%;
  height: 400px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #eef1fa;
  background: linear-gradient(to bottom, #eef1fa, rgba(238, 241, 250, 0));
}
.features-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -16px;
  margin-left: -16px;
}
.features-wrap:first-child {
  margin-top: -16px;
}
.features-wrap:last-child {
  margin-bottom: -16px;
}
.feature {
  position: relative;
  padding: 16px;
  width: 388px;
  max-width: 388px;
  flex-grow: 1;
}
.feature-inner {
  /* position: relative; */
  /* height: 100%; */
  background: #fff;
  padding: 48px 24px 72px;
}
.feature-inner::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 24px 48px rgba(1, 16, 10, 0.12);
  mix-blend-mode: multiply;
}
.feature-icon {
  position: relative;
  margin: 0 auto;
  width: 88px;
  height: 88px;
  border-radius: 50%;
}
.feature-icon::before {
  content: "";
  display: block;
  background: #fff;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 50%;
  box-shadow: 12px 24px 32px rgba(1, 16, 10, 0.12);
}
.feature-icon svg {
  position: relative;
}
@media (min-width: 641px) {
  .features .section-paragraph {
    padding-left: 72px;
    padding-right: 72px;
  }
  .features-wrap {
    padding-top: 24px;
    margin-right: -24px;
    margin-left: -24px;
  }
  .features-wrap:first-child {
    margin-top: -24px;
  }
  .features-wrap:last-child {
    margin-bottom: -24px;
  }
  .feature {
    padding: 24px;
  }
}
@media (min-width: 1025px) {
  .features .section-inner {
    padding-bottom: 168px;
  }
  .feature:nth-child(even) {
    top: 80px;
  }
}

.engine-label {
  color: #ff0000;
  font-size: small;
}
