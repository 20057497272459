.ColorInput-container {
  /* float: "left";
  margin-right: "40px"; */
}

.ColorInput-popup {
  position: absolute;
  z-index: 2;
}

.ColorInput-cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
